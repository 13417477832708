<!--
 * @Author: 林娴
 * @LastEditors: 林娴
-->
<template>
  <div class="wrap">
    <el-row>
      <el-col :span="22">
        <el-form ref="form" :model="form" label-width="140px" :rules="rules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="商户名称" prop="merchantSystemName">
                <el-input clearable v-model="form.merchantSystemName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="导航栏背景" prop="navigationBgPic">
                <el-upload
                  class="upload"
                  v-if="navigationBgPicFlag"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  name="file"
                  :headers="{
                      token: TOKEN
                  }"
                  accept=".png,.jpg"
                  :on-success="(e) => uploadSuccess(e, 'navigationBgPic')"
                  :on-error="uploadError">
                    <img v-if="form.navigationBgPic" class="image-upload" :src="form.navigationBgPic" alt="" srcset="" width="78" height="78">
                    <img v-else class="image-upload" src="@/assets/images/upload_image.png" alt="" srcset="">
                    <div class="upload-images-tip">
                      图片尺寸不小于346x136px，图片格式png/jpg
                    </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="登录页背景" prop="loginBgPic">
                <el-upload
                  class="upload"
                  v-if="loginBgPicFlag"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  name="file"
                  :headers="{
                      token: TOKEN
                  }"
                  accept=".png,.jpg"
                  :on-success="(e) => uploadSuccess(e,'loginBgPic')"
                  :on-error="uploadError">
                    <img v-if="form.loginBgPic" class="image-upload" :src="form.loginBgPic" alt="" srcset="" width="78" height="78">
                    <img v-else class="image-upload" src="@/assets/images/upload_image.png" alt="" srcset="">
                    <div class="upload-images-tip">
                      图片尺寸不小于346x136px，图片格式png/jpg
                    </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="菜单背景" prop="menuBgPic">
                <el-upload
                  class="upload"
                  v-if="menuBgPicFlag"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  name="file"
                  :headers="{
                      token: TOKEN
                  }"
                  accept=".png,.jpg"
                  :on-success="(e) => uploadSuccess(e,'menuBgPic')"
                  :on-error="uploadError">
                    <img v-if="form.menuBgPic" class="image-upload" :src="form.menuBgPic" alt="" srcset="" width="78" height="78">
                    <img v-else class="image-upload" src="@/assets/images/upload_image.png" alt="" srcset="">
                    <div class="upload-images-tip">
                      图片尺寸不小于346x136px，图片格式png/jpg
                    </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <!-- <el-col :span="4" style="text-align:right">
        <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
        <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
      </el-col> -->
    </el-row>
    <el-row class="footer">
      <el-button v-if="buttonAuth.includes('systemConfig:submit')" class="footer-btn" type="primary" @click="submit" v-repeatClick>{{$t('submit')}}</el-button>
    </el-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/apis'
import SystemConfigClass from './systemConfigClass'
import mixin from "@/mixins/dialogMixin";

export default {
  components: {
  },
  mixins: [mixin],
  data() {
    return {
      form: new SystemConfigClass('form'), // 实例化一个表单的变量
      rules: new SystemConfigClass("rule"), // 实例化一个表单的规则
      ImageUpload: apis.ImageUpload,
      loginBgPicFlag: true,
      menuBgPicFlag: true,
      navigationBgPicFlag: true,
    }
  },
  computed: {
    ...mapState(['Language', 'TOKEN', 'SystemIcon']),
  },
  activated() {
    this.searchData()
  },
  methods: {
    submit() {
      console.log(this.form)
      let reqUrl = apis.saveConfig
      if (this.form.id) {
        reqUrl = apis.updateConfig
      }
      this.$http.post(reqUrl, {
        ...this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success('提交成功！')
          this.searchData()
        }
      })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.get(apis.getConfig, {
        params: this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          this.form = res.data.rows
        }
      })
    },
    uploadSuccess(res, code) {
      console.log(res)
      this.form[code] = res.data.path
      this[`${code}Flag`] = false
      this.$nextTick(() => {
        this[`${code}Flag`] = true
      })
      this.$refs.form.validateField(code);
    },
    uploadError(res) {
      console.log(res)
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new SystemConfigClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.wrap {
  padding: 20px;
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    &-btn {
      width: 180px;
    }
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
