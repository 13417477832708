/*
 * @Author: 林娴
 * @LastEditors: 林娴
 */
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.merchantSystemName = ''
      this.loginBgPic = ''
      this.menuBgPic = ''
      this.navigationBgPic = ''
    } else if (type === 'rule') {
      this.merchantSystemName = [{
        required: true,
        message: '商户名称不能为空',
        trigger: 'blur',
      }]
      this.navigationBgPic = [{
        required: true,
        message: '导航栏背景图片不能为空',
        trigger: ['blur', 'change'],
      }]
      this.menuBgPic = [{
        required: true,
        message: '菜单背景图片不能为空',
        trigger: ['blur', 'change'],
      }]
      this.loginBgPic = [{
        required: true,
        message: '登录背景图片不能为空',
        trigger: ['blur', 'change'],
      }]
    }
  }
}
export default searchFrom
